function getErrorMessage(code) {
    switch(code) {
        case 'no_credit_card':
            return "You don't have a valid credit card. You can add one before booking";
        default:
            return 'Something went wrong';
    }
}

window.addEventListener('load', function() {
    VeeValidate.Validator.extend('expiration_date', {
        validate: function(value) {
            const datePieces = value.split('/');
            const expDate = new Date(datePieces[1], datePieces[0] - 1);

            return (/^(0[1-9]|1[0-2]|[1-9])\/(20[0-9][0-9])$/.test(value) && (expDate - new Date() > 0));
        },
        getMessage: 'Use a valid expiration date'
    });

    VeeValidate.Validator.extend('phone_number_length', {
        validate: function(value) {
            return value.replaceAll(' ', '').length === 10;
        },
        getMessage: 'The Mobile phone number field must have 10 digits.'
    });

    Vue.component('validation-provider', VeeValidate.ValidationProvider);
    Vue.component('validation-observer', VeeValidate.ValidationObserver);

    Vue.use(VeeValidate, {
        mode: 'eager'
    });

    const urlParams = new URLSearchParams(window.location.search);
    const bookingVue = new Vue({
        el: '#bookingVue',
        data: {
            addHelperToggle: undefined,
            dialogs: {
                date: false,
                time: false,
                cargo: false,
                surge: false,
                deleteCargo: false,
                notInYourCity: false,
                adBlock: false,
                error: false,
                _userCardNumber: undefined,
            },
            selectedItem: {
                item: {
                    id: null,
                    name: ''
                },
                quantity: 1,
                estimatedWeight: null,
                loadAddressOrder: 1,
                unloadAddressOrder: null
            },
            projectClonedBy: urlParams.get('cb'),
            selectedCargoItem: undefined,
            addAnotherItem: false,
            validFileTypes: ['image/png', 'image/jpeg', 'image/bmp', 'application/pdf'],
            loading: false,
            demoDialog: false,
            demoDialogTitle: undefined,
            demoDialogText: undefined,
            demoDialogNegativeButtonText: undefined,
            demoDialogPositiveButtonText: undefined,
            redirectCountDown: 30,
            editMode: false,
            numberOfLocations: 2,
            showTimePickerDialog: false,
            bindLocationMouseover: false
        },
        vuetify: vuetify,
        store: store,
        // TODO: use mapState
        computed: {
            STEP_WHERE: function() {
                return 1;
            },
            STEP_WHAT: function() {
                return 2;
            },
            STEP_VEHICLE_TYPE: function() {
                return 3;
            },
            STEP_EQUIPMENT: function() {
                return 4;
            },
            STEP_REVIEW: function() {
                return 5;
            },
            STEP_BOOK_COMPLETE: function() {
                return 6;
            },
            TIME_PICKER_MINUTES_INTERVAL: function() {
                return 15;
            },
            MIN_BOOKING_MINUTES_FROM_NOW_INTERVAL: function() {
                return 30;
            },
            DEFAULT_MAX_MULTI_STOP_ADDRESSES: function() {
                return 27;
            },
            DEFAULT_MIN_BOOKING_TIME_SLOT: function() {
                return new TimeSlot(5, 0, 'AM');
            },
            DEFAULT_MAX_BOOKING_TIME_SLOT: function() {
                return new TimeSlot(23, 30);
            },
            PROJECT_TYPE_ONE_DELIVERY_PRO_ONE_TRUCK: function() {
                return PROJECT_TYPE_ONE_DELIVERY_PRO_ONE_TRUCK;
            },
            PROJECT_TYPE_TWO_DELIVERY_PROS_ONE_TRUCK: function() {
                return PROJECT_TYPE_TWO_DELIVERY_PROS_ONE_TRUCK;
            },
            isCloned: function() {
                return this.projectClonedBy != null;
            },
            hitSteps: function() {
                return {};
            },
            noFirstLocation: function() {
                return this.$store.state.project.locations[0] === undefined || !(this.$store.state.project.locations[0].address !== '');
            },
            isCargoFieldEmpty: function() {
                return !this.selectedItem.item.name || !this.selectedItem.item.name.trim();
            },
            isCardOnFile: function() {
                return this.$store.state.user.card.onFile
            },
            isTimeAtLeast30MinInTheFuture: function(e) {
                if(!this.$store.getters.projectDateTime) {
                    return false;
                }

                let now = this.$store.getters.currentDateTime;
                let then = this.$store.getters.projectDateTime;

                const duration = moment.duration(then.diff(now));
                const timeInterval = duration.asMinutes();

                return (timeInterval >= 30);
            },
            isMultiStopEnabled: function() {
                return this.$store.state.multiStopEnabled;
            },
            finalStopOrder: function() {
                let locations = this.$store.state.project.locations
                return Math.max.apply(Math, locations.map(function(stop) {
                    return stop.addressOrder;
                }));
            },
            hasMoreThanTwoLocations: function() {
                return this.$store.state.project.locations.length > 2;
            },
            hasLimitOfAddressesReached: function() {
                return this.$store.state.project.locations.length === this.DEFAULT_MAX_MULTI_STOP_ADDRESSES;
            },
            areCategoriesEmpty: function() {
                return !this.loading && this.categories !== undefined && this.categories.length === 0;
            },
            errorDialog: {
                get: function() {
                    return this.$store.state.errorDialog;
                },
                set: function(value) {
                    this.$store.commit('setErrorDialog', value);
                }
            },
            showSignRegisterDialog: {
                get: function() {
                    return this.$store.state.showSignRegisterDialog;
                },
                set: function(value) {
                    this.$store.commit('setShowSignRegisterDialog', value);
                }
            },
            globalLoader: {
                get: function() {
                    return this.$store.state.loader;
                },
                set: function(value) {
                    this.$store.commit('changeLoader', value);
                }
            },
            firstLocationTimeZone: {
                get: function() {
                    return this.$store.state.project.firstLocationTimeZone;
                },
                set: function(value) {
                    this.$store.commit('setFirstLocationTimeZone', value);
                }
            },
            cargoItemRequiresHelper: function() {
                let itemsThatRequiresHelper = this.$store.state.project.cargoItems.filter(cargo => cargo.item.requiresHelper);
                return itemsThatRequiresHelper.length > 0 && !this.projectHasHelper;
            },
            cargoItemNotAllowsAssembly: function() {
                let itemsThatNotAllowsAssembly = this.$store.state.project.cargoItems.filter(cargo => cargo.item.allowedAssembly === false);
                return itemsThatNotAllowsAssembly.length > 0;
            },
            minBookingDateTime: function() {
                return this.$store.state.minBookingDateTime;
            },
            minBookingDateTimeAsString: function() {
                return this.minBookingDateTime.format();
            },
            minBookingTimeSlot: function() {
                return (this.$store.getters.projectDateTime && isDateToday(this.$store.getters.projectDateTime)) ?
                    moment(TimeSlot.fromMomentDate(this.minBookingDateTime)).format('HH:mm') :
                    moment(this.DEFAULT_MIN_BOOKING_TIME_SLOT, 'HH:mm').format('HH:mm');
            },
            step: {
                get: function() {
                    return this.$store.state.currentStep;
                },
                set: function(value) {
                    this.$store.commit('changeStep', value);
                }
            },
            tab: {
                get: function() {
                    return this.$store.state.tab;
                },
                set: function(value) {
                    this.$store.commit('changeTab', value);
                }
            },
            renderVehicleTypePrice: function() {
                return this.$store.state.project.pricingModel === "PER_MINUTE"
            },
            addHelperCard: function() {
                let vehicleType = this.$store.state.availableVehicleTypeList.find(el => el.vehicle_type_id === this.$store.state.project.vehicleType)
                if(vehicleType !== undefined) {
                    let vehicleTypeAllowHelper = vehicleType.allow_helper;
                    if(vehicleTypeAllowHelper == false) {
                        this.addHelperToggle = false;
                    }
                    return vehicleTypeAllowHelper == true;
                }
                return undefined;
            },
            categories: {
                get: function() {
                    return this.$store.state.categories;
                }
            },
            categoryIndex: {
                get: function() {
                    return this.$store.state.categoryIndex;
                },
                set: function(value) {
                    this.$store.commit('changeCategory', value);
                }
            },
            deviceData: {
                get: function() {
                    return this.$store.state.deviceData;
                },
                set: function(value) {
                    this.$store.commit('changeDeviceData', value);
                }
            },
            projectDate: {
                get: function() {
                    return this.$store.state.project.date;
                },
                set: function(value) {
                    this.$store.commit('changeProjectDate', value);
                }
            },
            projectDateFormatted: function() {
                return this.projectDate ? moment(this.projectDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : this.projectDate;
            },
            projectDateHint: function() {
                const projectAddressList = this.$store.getters.projectAddressList

                if(this.projectDate == null || projectAddressList == null || projectAddressList.length < 1) {
                    return ''
                }

                const formattedTimeZoneName = luxon.DateTime.fromFormat(this.projectDate,
                                                                        'yyyy-MM-dd',
                                                                        {zone: this.firstLocationTimeZone})
                                                            .toFormat('ZZZZ')

                return `Booking set for ${projectAddressList[0].city} time zone ${formattedTimeZoneName}.`
            },
            projectTime: {
                get: function() {
                    return this.$store.state.project.time;
                },
                set: function(value) {
                    this.$store.commit('changeProjectTime', value);
                }
            },
            projectTimeAmPm: function() {
                return this.projectTime ? moment(this.projectTime, 'HH:mm').format('h:mm A') : this.projectTime;
            },
            projectCargoAdditionalInfo: {
                get: function() {
                    return this.$store.state.project.cargoAdditionalInfo;
                },
                set: function(value) {
                    this.$store.commit('changeProjectCargoAdditionalInfo', value);
                }
            },
            projectSelectedEquipment: {
                get: function() {
                    return this.$store.state.project.selectedEquipment;
                },
                set: function(value) {
                    this.$store.commit('changeProjectSelectedEquipment', value);
                }
            },
            cargoRequiresHelperWarnMessage: {
                get: function() {
                    let itemsThatRequiresHelper = this.$store.state.project.cargoItems.filter(i => i.item.requiresHelper);

                    if(itemsThatRequiresHelper.length > 1) {
                        return "Cargo items in your Project require 2 people to lift safely. If you are unable to provide assistance, please add a helper"
                    }

                    return itemsThatRequiresHelper.map(a =>
                        '<strong>' + a.item.name + '</strong>').join(", ") + " requires 2 people to lift safely. If you are unable to provide assistance, please add a helper";
                }
            },
            cargoItemNotAllowsAssemblyWarnMessage: {
                get: function() {
                    return "GoShare is not currently able to offer assembly services for: " + this.$store.state.project.cargoItems.filter(i => !i.item.allowedAssembly).map(a =>
                        '<strong>' + a.item.name + '</strong>').join(", ");
                }
            },
            projectVehicleType: {
                get: function() {
                    return this.$store.state.project.vehicleType;
                },
                set: function(value) {
                    this.$store.commit('changeProjectVehicleType', value);
                }
            },
            projectHasHelper: {
                get: function() {
                    return this.$store.state.project.projectType === PROJECT_TYPE_TWO_DELIVERY_PROS_ONE_TRUCK;
                },
                set: function(value) {
                    if(value === undefined) {
                        this.$store.commit('changeProjectType', value);
                    } else {
                        this.$store.commit('changeProjectType', value ? PROJECT_TYPE_TWO_DELIVERY_PROS_ONE_TRUCK : PROJECT_TYPE_ONE_DELIVERY_PRO_ONE_TRUCK);
                    }
                }
            },
            projectPromoCode: {
                get: function() {
                    return this.$store.state.project.promoCode;
                },
                set: function(value) {
                    const toUpperCase = value.toUpperCase();
                    this.$store.commit('changeProjectPromoCode', toUpperCase);
                }
            },
            userBasicInfo: {
                get: function() {
                    return {
                        firstName: this.$store.state.user.firstName,
                        lastName: this.$store.state.user.lastName,
                        email: this.$store.state.user.email,
                        phone: this.$store.state.user.phone,
                        photo: this.$store.state.user.photo,
                    }
                }
            },
            userFirstName: {
                get: function() {
                    return this.$store.state.user.firstName;
                },
                set: function(value) {
                    this.$store.commit('changeUserFirstName', value);
                }
            },
            userEmail: {
                get: function() {
                    return this.$store.state.user.email;
                },
                set: function(value) {
                    this.$store.commit('changeUserEmail', value);
                }
            },
            userTos: {
                get: function() {
                    return this.$store.state.user.tos;
                },
                set: function(value) {
                    this.$store.commit('changeUserTos', value);
                }
            },
            isDemo: {
                get: function() {
                    return this.$store.state.user.isDemo;
                },
                set: function(value) {
                    this.$store.commit('changeIsDemo', value);
                }
            },
            category: function() {
                return this.$store.state.categoryIndex !== undefined ? this.$store.state.categories[this.$store.state.categoryIndex].alias : undefined;
            },
            categorySteps: function() {
                if(this.category === 'move') {
                    // TODO: move steps into constants
                    return [1, 2, 3, 4, 5];
                }

                if(['labor_only', 'courier'].includes(this.category)) {
                    return [1, 2, 5];
                }
            },
            deleteCargo: {
                get: function() {
                    return this.dialogs.deleteCargo;
                },
                set: function(value) {
                    this.dialogs.deleteCargo = value;
                }
            },
            cargoDialog: {
                get: function() {
                    return this.dialogs.cargo;
                },
                set: function(value) {
                    this.dialogs.cargo = value;
                }
            },
            toast: {
                get: function() {
                    return this.$store.state.toast;
                },
                set: function(value) {
                    this.$store.commit('setToast', value);
                }
            },
            clonedFromProjectId: {
                get: function() {
                    return this.$store.state.clonedFromProjectId;
                },
                set: function(value) {
                    this.$store.commit('setClonedFromProjectId', value);
                }
            },
            successfullyBooked: function() {
                return this.$store.state.successfullyBooked;
            }
        },
        watch: {
            addHelperToggle: function(value) {
                this.projectHasHelper = value;
            },
            categoryIndex: function(value) {
                if(value === undefined) {
                    this.$store.commit('changeStep', 0);
                    return;
                }
                this.$store.commit('changeStep', 1);
            },
            step: function(step, previousStep) {
                const vm = this;

                window.location.hash = step;

                switch(step) {
                    case vm.STEP_VEHICLE_TYPE:
                        this.$store.watch(function(state, getters) {
                            return getters.projectVehicleTypeAllowsHelper
                        }, function(value) {
                            if(value === false && vm.projectHasHelper) {
                                vm.$store.commit('changeProjectType', PROJECT_TYPE_ONE_DELIVERY_PRO_ONE_TRUCK);
                            }
                        });
                        this.updateVehicleTypes();
                        break;

                    case vm.STEP_EQUIPMENT:
                        this.$store.dispatch('getAvailableEquipment').then(availableEquipment => {
                            if(previousStep > step) {
                                return;
                            }

                            const requiredEquipment = availableEquipment.filter(equipment => this.$store.getters.equipmentRequiredByCargo[equipment.id]).map(equipment => equipment.id)
                            const userSelectedAndRequiredEquipment = this.projectSelectedEquipment.concat(requiredEquipment)
                            this.projectSelectedEquipment = userSelectedAndRequiredEquipment.filter((item, index) => userSelectedAndRequiredEquipment.indexOf(item) === index)
                        });
                        break;

                    case vm.STEP_REVIEW:
                        this.updateEstimate();
                        if(!this.isCardOnFile) {
                            this.getUserCard()
                        }
                }

                vm.sendAnalytics(step);
            },
            projectHasHelper: function(value) {
                this.updateVehicleTypes();
            },
            cargoDialog: function(value) {
                if(value) {
                    if(this.$refs.gsCargoAutocomplete && this.selectedItem.item.name.trim()) {

                        let selectedItem = {
                            text: this.selectedItem.item.name,
                            value: {
                                id: this.selectedItem.item.id,
                                value: this.selectedItem.item.name
                            },
                        };

                        this.$refs.gsCargoAutocomplete.itemList.push(selectedItem);
                        this.$refs.gsCargoAutocomplete.searchString = this.selectedItem.item.name;
                    }
                } else {
                    this.editMode = false;
                    this.clearSelectedItem();
                }
            },
            projectDate: function(value) {
                this.projectTime = '';
            }
        },
        methods: {
            getVehicleTypeById: function(id) {
                const filtered = this.$store.state.availableVehicleTypeList.filter(function(vehicleType) {
                    return vehicleType.vehicle_type_id === id;
                });
                const response = filtered.length > 0 ? filtered[0] : {vehicle_type_title: 'helper'};
                return response;
            },
            timeAtLeast30MinInTheFutureValidation: function(value) {
                this.updateMinBookingDateTime();
                return this.isTimeAtLeast30MinInTheFuture || 'Time must be at least 30 minutes in the future';
            },
            onFocusDateField: function(e) {
                this.dialogs.date = true;
            },
            onFocusTimeField: function(e) {
                this.showTimePickerDialog = true;
            },
            onSelectProjectTime: function(time) {
                this.projectTime = time;
            },
            onCloseTimePicker: function() {
                this.showTimePickerDialog = false;
            },
            isEquipmentSelectedById: function(equipmentId) {
                return this.projectSelectedEquipment.includes(equipmentId)
            },
            allowedMinutes: function(min) {
                return min % this.TIME_PICKER_MINUTES_INTERVAL === 0;
            },
            nextStep: function() {
                if(!this.validateStep()) {
                    return;
                }
                this.$vuetify.goTo(this.$refs.signInDialog);

                for(let dialog in this.dialogs) {
                    this.dialogs[dialog] = false;
                }

                let nextStep = this.step;
                do {
                    ++nextStep;
                } while(!this.categorySteps.includes(nextStep));

                this.step = nextStep;
            },
            previousStep: function() {
                let previousStep = this.step;
                do {
                    --previousStep;
                } while(!this.categorySteps.includes(previousStep));

                this.step = previousStep;
            },
            saveCargoItem: function() {
                if(this.isCargoFieldEmpty || !this.$refs.cargoForm.validate()) {
                    return;
                }

                this.$store.commit('addCargoItem', this.selectedItem);
                this.clearSelectedItem();
                this.clearCargoItemDialog(!this.addAnotherItem);
            },
            clearCargoItemDialog: function(closeDialog) {
                closeDialog = closeDialog || false;
                this.$refs.gsCargoAutocomplete.searchString = null;
                this.$refs.cargoForm.resetValidation();
                this.$refs.cargoListForm.validate();
                this.dialogs.cargo = !closeDialog;
            },
            updateCargoItem: function() {
                if(this.isCargoFieldEmpty || !this.$refs.cargoForm.validate()) {
                    return;
                }

                this.$store.commit('updateCargoItem', this.selectedItem);
                this.editMode = false;
                this.clearSelectedItem();
                this.$refs.cargoForm.resetValidation();
                this.$refs.cargoListForm.validate();

                if(!this.addAnotherItem) {
                    this.dialogs.cargo = false;
                }
            },
            clearSelectedItem: function() {
                this.selectedItem = {
                    item: {
                        id: null,
                        name: ''
                    },
                    quantity: 1,
                    estimatedWeight: null,
                    loadAddressOrder: 1,
                    unloadAddressOrder: null
                }
            },
            openDeleteCargoItemModal(index) {
                this.selectedCargoItem = index;
                this.dialogs.deleteCargo = true;
            },
            openEditCargoItemModal(item, index) {
                this.selectedCargoItem = index;
                this.selectedItem = item;
                this.editMode = true;
                this.dialogs.cargo = true;
            },
            closeDeleteCargoItemModal() {
                this.selectedCargoItem = undefined;
                this.dialogs.deleteCargo = false;
            },
            removeCargoItem: function() {
                this.$store.commit('removeCargoItem', this.selectedCargoItem);
                this.closeDeleteCargoItemModal();
            },
            clickFileInput: function() {
                this.$refs.photoInput.$refs.input.click()
            },
            addPhoto: function(image) {
                if(!this.validFileTypes.includes(image.type)) {
                    // TODO catch error
                    return;
                }

                let imageObject = {
                    file: image,
                    src: ''
                };

                const $store = this.$store
                const fileReader = new FileReader();
                fileReader.onload = function(event) {
                    imageObject.src = event.target.result;
                    $store.commit('addProjectImage', imageObject);
                };
                fileReader.readAsDataURL(image);

                this.$refs.photoInput.$refs.input.value = null;

            },
            removePhoto: function(index) {
                this.$store.commit('removeProjectImage', index);
            },
            updateVehicleTypes: function() {
                const vm = this;
                this.loading = true;
                this.$store.dispatch('getAvailableVehicleTypes').then(function() {
                    vm.loading = false;
                });
            },
            updateEstimate: function() {
                const vm = this;
                if(vm.$validator.errors.first('booking_promo_code')) {
                    return;
                }

                this.$store.dispatch('getEstimate').catch(function() {
                    vm.showToast();
                })
            },
            getUserCard: function() {
                const vm = this;
                this.loading = true;
                this.$store.dispatch('getUserCard')
                    .then(() => {
                        this.loading = false;
                    }).catch(function() {
                    vm.loading = false;
                })
            },
            addLocation: function() {
                const vm = this;
                if(vm.hasLimitOfAddressesReached) {
                    vm.showToast('The limit of addresses was reached.');
                    return
                }
                this.$store.dispatch('addLocation', 'drop-off');

                Vue.nextTick(function() {
                    vm.bindLocationMouseover = false;
                    vm.$forceUpdate();
                })
            },
            startDrag: function(event, addressOrderFrom) {
                event.dataTransfer.dropEffect = 'move';
                event.dataTransfer.effectAllowed = 'move';
                event.dataTransfer.setData('addressOrderFrom', addressOrderFrom);
            },
            startDrop: function(event, addressOrderTo) {
                const addressOrderFrom = parseInt(event.dataTransfer.getData('addressOrderFrom'));
                if(addressOrderFrom !== addressOrderTo) {
                    this.$store.commit('moveLocation', {addressOrderFrom, addressOrderTo});
                }
                const $locationFrom = $(`.v-timeline-item[data-address-order='${addressOrderFrom}']`);
                $locationFrom.removeClass('drag-and-drop');
                $locationFrom.prev().removeClass('gs-drop-active');
                $('.v-timeline-item').removeClass('is-hover');
                const $elem = $(event.target);
                if($elem.has('.mdi-drag').length > 0) {
                    $elem.closest('.v-timeline-item').addClass('is-hover');
                }
            },
            submitStepWhere: function() {
                if(!this.validateStep()) {
                    return;
                }

                const vm = this;
                this.loading = true;
                this.$store.dispatch('hasService').then(function() {
                    vm.$store.dispatch('getPriceMultiplier').then(function() {
                        vm.loading = false;
                        if(vm.$store.state.project.priceMultiplier.multiplier > 1) {
                            vm.dialogs.surge = true;
                            vm.$analytics.logEvent({firebase: {event: 'alerted_about_dynamic_rate'}})
                        } else {
                            if(vm.$store.state.project.serviceAvailable.status) {
                                vm.nextStep();
                            } else {
                                vm.loading = false;
                            }
                        }
                    }).catch(function(error) {
                        vm.loading = false;

                        if(vm.$store.state.project.serviceAvailable.errorCode === "zones_not_available") {
                            vm.dialogs.notInYourCity = true;
                        }
                    })
                }).catch(function(error) {
                    vm.loading = false;
                });
            },
            submitNotInYourCityDialog: function() {
                if(!this.$refs.notInYourCityForm.validate()) {
                    return;
                }
                const vm = this;
                this.loading = true;

                vm.$store.dispatch('postNotInYourCity').then(function() {
                    vm.loading = false;
                    vm.dialogs.notInYourCity = false;
                }).catch(function(error) {
                    vm.loading = false;
                });
            },
            isVehicleTypeSelected: function(vehicleTypeId) {
                return vehicleTypeId === this.$store.state.project.vehicleType;
            },
            selectVehicleTypeByTab: function(vehicleTypeId) {
                this.$store.state.project.vehicleType = vehicleTypeId;
                this.addHelperCard();
            },
            validateStep: function() {
                return this.$refs['step' + this.step].validate();
            },
            showToast: function(content) {
                this.toast.show = true;
                this.toast.content = content;
            },
            showErrorDialog: function(title, content, callback) {
                this.$store.commit('setErrorDialog', {
                    title,
                    content,
                    callback
                });
                this.dialogs.error = true;
            },
            applyPromoCode: function() {
                if(!this.isDemo && !this.$store.getters.userSignedIn) {
                    this.showSignRegisterDialog = true

                    return
                }
                this.updateEstimate()
            },
            requestBraintreeDeviceDataAndBook: function() {
                if(!this.$refs.formBook.validate()) {
                    return;
                }

                this.loading = true;
                const vm = this;

                if(vm.deviceData) {
                    vm.book();
                    return;

                } else {
                    requestBraintreeDeviceData(function(deviceData) {
                        vm.deviceData = deviceData;
                        vm.book();
                    }, function() {
                        vm.loading = false;
                        vm.showToast('Something went wrong. Try again in a moment.')
                    });
                }
            },
            updateMinBookingDateTime: function() {
                this.$store.dispatch('updateMinBookingDateTime', {
                    TIME_PICKER_MINUTES_INTERVAL: this.MIN_BOOKING_MINUTES_FROM_NOW_INTERVAL,
                    DEFAULT_MIN_BOOKING_TIME_SLOT: this.DEFAULT_MIN_BOOKING_TIME_SLOT,
                    DEFAULT_MAX_BOOKING_TIME_SLOT: this.DEFAULT_MAX_BOOKING_TIME_SLOT,
                });
            },
            book: function() {
                this.updateMinBookingDateTime();

                if(!this.isTimeAtLeast30MinInTheFuture) {
                    this.showErrorDialog('Project time is not valid anymore',
                        'Projects must be booked at least 30 minutes in the future. You will be required to select a new date and time.',
                        function() {
                            window.location.hash = 1;
                        });
                    this.loading = false;
                } else {
                    const vm = this;
                    this.$store.dispatch('bookNow').then(function(response) {
                        if(!Vue.config.devtools) {
                            let sessionId = Math.random() * 10000000000000;
                            let nextdoorElement = document.createElement('img');
                            nextdoorElement.src = 'https://pubads.g.doubleclick.net/activity;xsp=4702645;ord=' + sessionId + '?';
                            nextdoorElement.width = 1;
                            nextdoorElement.height = 1;
                            document.body.appendChild(nextdoorElement);
                        }
                        vm.$vuetify.goTo(vm.$refs.signInDialog);
                        vm.loading = false;

                        let url = '/trucks/dashboard';

                        switch(vm.$store.state.project.pricingModel) {
                            case 'OFFER':
                                url = '/trucks/dashboard/projects/' + response.job_id + '/search-delivery-pro';
                                window.location = url;
                                break;
                            default:
                                vm.$store.commit('changeStep', 6);
                                vm.startRedirectCountDown(30, url);
                                break;
                        }

                    }).catch(function(errorResponse) {
                        vm.showToast(errorResponse.message);
                        vm.loading = false;
                    });
                }
            },
            startRedirectCountDown: function(time, whereTo) {
                this.redirectCountDown = time;

                if(time === 0) {
                    window.location = whereTo;
                } else {
                    setTimeout(this.startRedirectCountDown, 1000, --time, whereTo);
                }
            },
            sendAnalytics: function(step, customObject) {
                if(step !== undefined) {
                    if(step < 1 || step > 6) {
                        return;
                    }
                    let pageIndex = step - 1;

                    // Vehicle info step requires this additional page view hit
                    if(step === this.STEP_VEHICLE_TYPE && !this.hitSteps[step]) {
                        // send_page_view({step: 'booking/step3', title: 'Booking step 3: Help Info'});
                    }

                    if(!this.hitSteps[step]) {
                        this.$analytics.logEvent(this.$store.getters.analyticsEvent);
                        this.hitSteps[step] = true;
                    }

                    // TODO manually call after booked using the $analytics.logEvent() method
                    if(step === 6) {
                        _fbq('track', 'BookingComplete');
                        gtag('event', 'gs-booking-complete');
                    }
                }

                if(customObject !== undefined) {
                    this.$analytics.logEvent(customObject);
                }
            },
            reassignFormInputs: function(form) {
                const inputs = [];
                // Copied from VForm's previous life* which had a getInputs() function
                const search = (children, depth = 0) => {
                    for(let index = 0; index < children.length; index++) {
                        const child = children[index];
                        if(child.errorBucket !== undefined) {
                            inputs.push(child);
                        } else {
                            search(child.$children, depth + 1);
                        }
                    }
                    if(depth === 0) {
                        return inputs;
                    }
                };
                search(form.$children);
                form.inputs = inputs;
            },
            showCreditCardDemoDialog: function() {
                this.demoDialogTitle = '';
                this.demoDialogText = 'Demo mode doesn\'t use a credit card.';
                this.demoDialogNegativeButtonText = 'OK';
                this.demoDialogPositiveButtonText = undefined;
                this.demoDialog = true;
            },
            showBookingDemoDialog: function() {
                this.demoDialogTitle = 'Booking demo completed';
                this.demoDialogText = 'Contact the GoShare sales team to book your deliveries as one of our partners.';
                this.demoDialogNegativeButtonText = 'Close';
                this.demoDialogPositiveButtonText = 'Become a partner';
                this.demoDialog = true;
            },
            defineDebouncedMethods: function() {
                /*
                 * The method submitStepReview, which handles clicks to the Order Now button, has been
                 * moved to the created() lifecycle hook so that it can be dynamically monitored to be called
                 * only once within 3 seconds. Doing that in this method object would cause
                 *  the method to lose the Vue context and throw errors as the $refs would be undefined.
                 */
                this.submitStepReview = once(() => {
                    if(!this.$refs.formBook.validate()) {
                        return;
                    }

                    if(this.isDemo) {
                        this.showBookingDemoDialog();

                        return;
                    }

                    if(!this.$store.getters.userSignedIn || !this.$store.state.user.card.onFile) {
                        this.showSignRegisterDialog = true;

                        return;
                    }

                    this.requestBraintreeDeviceDataAndBook();
                }, 1500)
            },
            getEquipmentRequiredByCargoItemLabel: function(equipmentId) {
                const numberOfItems = this.$store.getters.equipmentRequiredByCargo[equipmentId].length
                const append = numberOfItems > 2 ? ` and ${numberOfItems - 1} other items` : ' and 1 other item'

                return `Required by ${this.$store.getters.equipmentRequiredByCargo[equipmentId][0]}${numberOfItems > 1 ? append : ''}`
            },
            onAddCreditCardButtonClicked: function() {
                if(this.isDemo) {
                    this.showCreditCardDemoDialog();

                    return;
                }

                this.showSignRegisterDialog = true
            }
        },
        created: function() {
            store.dispatch('isMultiStopEnabled');
            this.defineDebouncedMethods();
        },
        updated: function() {
            if(!this.isMobile && $('.v-timeline-item[draggable]').length && !this.bindLocationMouseover) {
                this.bindLocationMouseover = true;
                const $locationNumberIcon = $('.v-timeline-item__inner-dot:has(.mdi-drag)');
                $locationNumberIcon.off('mouseover').on('mouseover', function() {
                    $(this).closest('.v-timeline-item').addClass('is-hover');
                }).off('mouseout').on('mouseout', function() {
                    $(this).closest('.v-timeline-item').removeClass('is-hover');
                });
            }
        },
        mounted: function() {
            let vm = this;
            this.sendAnalytics(1);
            // store.dispatch('getCategories');

            window.location.hash = 1;

            window.onhashchange = function(evt) {
                const requestedStep = +window.location.hash.slice(1);

                if(requestedStep <= 0 || requestedStep > 5) {
                    window.location.hash = vm.step;
                } else if(requestedStep < vm.step) {
                    vm.step = requestedStep
                } else if(requestedStep === vm.step + 1) {
                    if(requestedStep === 2) {
                        vm.submitStepWhere();
                    } else {
                        vm.nextStep();
                    }
                } else {
                    window.location.hash = vm.step;
                }
            }

            if(this.isCloned && this.$store.getters.userSignedIn) {
                this.clonedFromProjectId = this.projectClonedBy;
                this.$store.dispatch('fetchCloneProjectInfo').then(() => {
                    if(this.$store.state.project.projectType !== undefined) {
                        this.addHelperToggle = this.projectHasHelper;
                    }
                }).catch(errorMessage => {
                    this.$store.dispatch('addLocation', 'pickup');
                    this.$store.dispatch('addLocation', 'drop-off');
                    vm.showToast(errorMessage);
                });
            } else {
                this.$store.dispatch('addLocation', 'pickup');
                this.$store.dispatch('addLocation', 'drop-off');
            }

            this.$store.dispatch('getPossibleCargoItemWeightList');
            this.updateMinBookingDateTime();
        }
    });

    /** Branch SDK **/
    branch.init(branch_io_key, function(err, data) {
        if(err) {
            bookingVue.showToast(err.message)
        } else {
            bookingVue.projectPromoCode = data.data_parsed.promo_code || '';
        }
    });

    if(!document.getElementById('ad-blocker-is-enabled') && /[?&]_branch_match_id=/.test(location.search)) {
        bookingVue.$data.dialogs.adBlock = true
    }
});
